
import { defineComponent, ref, watch } from "vue";
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonInput,
  IonText,
  IonPopover,
  IonDatetime,
} from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { useLocalStorage } from "@/libs/localStorage";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import { format, parseISO } from "date-fns";

import VendorNavComponent from "./VendorNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "Edit Product",
  setup() {
    const { t, te } = useI18n();
    const storeX = useStore();
    const router = useRouter();
    const route = useRoute();
    const { createLoading, openToast, formatNumberLocale } =
      useGlobalFunctions();
    const { getLocalStorageData } = useLocalStorage();
    const productId = ref(route.params.productId);
    const templateId = ref(null);
    const productName = ref(null);
    const productEditableData = ref([]);
    const productData = ref(null);
    const templCustomerGroups = ref(null);
    const productFormData = ref({});
    const qtyEditable = ref(false);
    const priceEditable = ref(false);
    const allowBackorders = ref(false);
    const allowGroupPrice = ref(false);
    const allowSpecialPrice = ref(false);
    const isConfigurable = ref(false);
    const inventory = ref({
      backorders: null,
      quantity: null,
    });

    const configurableProducts = ref({});
    const configurableInventory = ref({});

    const priceGroups = ref([]);

    const configurableSuperAttributes = ref(null);

    const editorOptions = ref({
      height: 300,
      width: 250,
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
    });

    const groupPriceMin = ref(getLocalStorageData("groupPriceMin"));
    const groupPriceMax = ref(getLocalStorageData("groupPriceMax"));

    const priceData = ref({
      price: null,
      group_price: [],
      special_price: "",
      special_from_date: "",
      special_to_date: "",
    });
    const configurablePrices = ref({});

    const fromDateCalendar = ref();
    const toDateCalendar = ref();

    const addGropuPrice = () => {
      if (priceData.value.group_price.length < priceGroups.value.length) {
        const maxGroupPriceV =
          Number(priceData.value.price) -
          (Number(groupPriceMax.value) / 100) * Number(priceData.value.price);
        priceData.value.group_price.push({
          cust_group: priceGroups.value[0].value,
          price: maxGroupPriceV,
        });
      }
    };

    const removeGropuPrice = (index) => {
      priceData.value.group_price.splice(index, 1);
    };

    const getGroupLabel = (groupOption) => {
      let label;
      priceGroups.value.forEach((group) => {
        if (group.value === groupOption.cust_group) {
          label = group.label;
        }
      });
      return label;
    };

    const formatDate = (value: string) => {
      return format(parseISO(value), "MMM dd yyyy");
    };

    const dateChanged = (ev, type) => {
      if (type == "from_date") {
        priceData.value.special_from_date = formatDate(ev.detail.value);
        fromDateCalendar.value.$el.confirm(true);
      } else {
        priceData.value.special_to_date = formatDate(ev.detail.value);
        toDateCalendar.value.$el.confirm(true);
      }
    };

    const handleGroupPrices = () => {
      priceGroups.value.forEach((group) => {
        let itemFound = false;
        if (productData.value && productData.value.group_price.length) {
          productData.value.group_price.forEach((productGroup, index) => {
            if (group.value == productGroup.cust_group) {
              priceData.value.group_price[index].price = productGroup.price * 1;
              itemFound = true;
            }
            if (index + 1 == productData.value.length) {
              if (!itemFound) {
                priceData.value.group_price.push({
                  cust_group: group.value,
                  price: 0,
                });
              }
            }
          });
        } else {
          priceData.value.group_price.push({
            cust_group: group.value,
            price: 0,
          });
        }
      });
    };

    const getProductEditableData = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getProductEditableData"), {
          store_view: storeX.state.storeCode,
          product_id: productId.value,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            templateId.value = resp.template.entity_id;
            productName.value = resp.template.product.name;
            productEditableData.value = resp.template.attributes;
            productData.value = resp.template.product;
            priceData.value.price = resp.template.product.price * 1;
            priceData.value.group_price = resp.template.product.group_price;
            templCustomerGroups.value = resp.template.customer_groups;

            Object.keys(resp.template.attributes).forEach((key) => {
              const attribute = resp.template.attributes[key];
              productFormData.value[attribute.attribute_code] =
                attribute.template_value;
            });

            qtyEditable.value = resp.template.qty_editable;
            priceEditable.value = resp.template.price_editable;
            allowBackorders.value = resp.template.seller_allow_backorders;
            allowGroupPrice.value = resp.template.seller_allow_group_price;
            allowSpecialPrice.value = resp.template.seller_allow_special_price;
            // if (productData.value.group_price.length) {
            //   priceData.value.group_price = [];
            //   productData.value.group_price.forEach((group) => {
            //     priceData.value.group_price.push({
            //       cust_group: group.cust_group,
            //       price: group.price * 1,
            //     });
            //   });
            // }
            handleGroupPrices();

            if (
              resp.template.base_attributes.type_id.attr_value ===
              "configurable"
            ) {
              isConfigurable.value = true;
            } else {
              isConfigurable.value = false;
              inventory.value.backorders = resp.template.stock_data.backorders;
              inventory.value.quantity = resp.template.stock_data.qty * 1;
            }
            if (resp.template.simple_products) {
              Object.keys(resp.template.simple_products).forEach((key) => {
                const simpleProduct = resp.template.simple_products[key];
                if (simpleProduct.stock_data) {
                  configurableInventory.value[key] = {
                    backorders: null,
                    quantity: simpleProduct.stock_data.qty * 1,
                  };
                } else {
                  configurableInventory.value[key] = {
                    backorders: null,
                    quantity: null,
                  };
                }
                configurableProducts.value[key] = simpleProduct;
              });
            }
            if (resp.template.super_attributes) {
              Object.keys(resp.template.super_attributes).forEach(
                (attributeCode) => {
                  const superAttribute =
                    resp.template.super_attributes[attributeCode];
                  if (!configurablePrices.value[attributeCode]) {
                    configurablePrices.value[attributeCode] = {};
                  }
                  Object.keys(superAttribute).forEach((sku) => {
                    const superAttributeOption = superAttribute[sku];
                    configurablePrices.value[attributeCode][sku] = {
                      pricing_value:
                        Number(superAttributeOption.pricing_value) * 1,
                      pricing_is_percent:
                        superAttributeOption.pricing_is_percent,
                    };
                  });
                }
              );
              configurableSuperAttributes.value =
                resp.template.super_attributes;
            }
          } else {
            templateId.value = null;
            productName.value = null;
            productEditableData.value = [];
            productData.value = null;
            templCustomerGroups.value = null;
            qtyEditable.value = false;
            priceEditable.value = false;
            allowBackorders.value = false;
            allowGroupPrice.value = false;
            allowSpecialPrice.value = false;
            configurableProducts.value = null;
            configurableSuperAttributes.value = null;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const saveProductAction = async (event) => {
      event.preventDefault();
      const data = productFormData.value;
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("sellerSaveProductData"), {
          product_data: data,
          product_id: productId.value,
          template_id: templateId.value,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            loading.dismiss();
            const msg = t("addproduct.successAddingProduct");
            const duration = 5000;
            openToast(msg, "success", false, duration);
            router.push("/vendor/products-list/");
          } else {
            loading.dismiss();
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const numberOnlyValidation = (event) => {
      const pattern = /[0-9.,]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    };

    const saveProductQuantity = async (event) => {
      event.preventDefault();
      const data = inventory.value;
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("saveProductEditableInventory"),
          {
            inventory_data: data,
            configurableInventory_data: configurableInventory.value,
            product_id: productId.value,
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            const msg = t("addproduct.successAddingInventory");
            const duration = 5000;
            openToast(msg, "success", false, duration);
            //router.replace("/vendor/products-list");
            loading.dismiss();
          } else {
            loading.dismiss();
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getCustomerPriceGroups = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getCustomerPriceGroups"), {
          store_view: storeX.state.storeCode,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            priceGroups.value = resp.items;
            handleGroupPrices();
          } else {
            priceGroups.value = [];
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const updateGroupPrice = (value, index) => {
      const groupId = priceData.value.group_price[index].cust_group;
      if (groupId == 2 && priceEditable.value) {
        const newPrice =
          Number(value) + (Number(groupPriceMax.value) / 100) * Number(value);
        priceData.value.price = newPrice.toString();
      }
    };

    const saveProductPrices = async (event) => {
      event.preventDefault();
      const data = priceData.value;
      const loading = await createLoading(t("pleaseWait"));
      const shouldValidateGroupPrice =
        allowGroupPrice.value && priceEditable.value ? true : false;

      if (priceData.value.group_price.length == 0 && shouldValidateGroupPrice) {
        openToast(
          t("addproduct.groupPriceRequiredError"),
          "danger",
          false,
          5000
        );
        return false;
      }

      let priceValidated = false;
      if (shouldValidateGroupPrice) {
        priceData.value.group_price.forEach((group) => {
          const groupId = group.cust_group;
          let minEndUserPrice = 0;
          if (groupId == 2) {
            const value = group.price;
            minEndUserPrice =
              Number(value) +
              (Number(groupPriceMin.value) / 100) * Number(value);
          }
          if (Number(priceData.value.price) < minEndUserPrice) {
            openToast(
              t("addproduct.groupPriceError", {
                minPrice: formatNumberLocale(minEndUserPrice),
              }),
              "danger",
              false,
              5000
            );
            return false;
          } else {
            priceValidated = true;
          }
        });
      } else {
        priceValidated = true;
      }

      if (!priceValidated) return false;

      loading.present();
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("saveProductEditablePrice"),
          {
            price_data: data,
            configurable_prices_data: configurablePrices.value,
            product_id: productId.value,
          }
        )
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            const msg = t("addproduct.successAddingPrice");
            const duration = 5000;
            openToast(msg, "success", false, duration);
            router.push("/vendor/products-list");
            loading.dismiss();
          } else {
            loading.dismiss();
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
            } else {
              openToast(response.error.message, "danger");
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const backToList = () => {
      router.push("/vendor/products-list");
    };

    watch(
      () => storeX.state.storeCode,
      () => {
        getCustomerPriceGroups();
        getProductEditableData();
      }
    );

    const ionViewWillEnter = () => {
      getCustomerPriceGroups();
      getProductEditableData();
    };

    return {
      t,
      te,
      storeX,
      ionViewWillEnter,
      productName,
      productEditableData,
      productData,
      templCustomerGroups,
      productFormData,
      saveProductAction,
      qtyEditable,
      priceEditable,
      allowBackorders,
      allowGroupPrice,
      allowSpecialPrice,
      isConfigurable,
      inventory,
      saveProductQuantity,
      numberOnlyValidation,
      configurableInventory,
      configurableProducts,
      configurableSuperAttributes,
      configurablePrices,
      addGropuPrice,
      removeGropuPrice,
      getGroupLabel,
      formatDate,
      priceGroups,
      priceData,
      fromDateCalendar,
      toDateCalendar,
      dateChanged,
      updateGroupPrice,
      saveProductPrices,
      editorOptions,
      backToList,
    };
  },

  components: {
    IonPage,
    IonContent,
    MainHeader,
    VendorNavComponent,
    MainFooter,
    Editor,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonInput,
    IonText,
    IonPopover,
    IonDatetime,
  },
});
